/* Not using light or extra bold for now, but files are in the folder if we need them */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-Italic.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-Medium.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-MediumItalic.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-Medium.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-MediumItalic.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-SemiBold.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-Bold.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-BoldItalic.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-Light.ttf);
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-stretch: 100%;
    font-display: swap;
    src: local('Poppins'), url(/assets/fonts/Poppins/Poppins-ExtraLight.ttf);
}
